<template>
  <v-container>
    <environment-warning v-if="environmentFetchError" />

    <div v-else-if="environment">
      <environment-info
        @launch-lobby="launchLobby"
        :canViewCore="canViewCore"
        :canViewPromotions="canViewPromotions"
        :environment="environment"
      />
      <v-row class='ma-5'>
        <v-tabs v-model="tab">
          <v-tabs-slider color="primary" />
          <v-tab
            v-for="section in sections"
            :key="section"
            :disabled="isTabDisabled(section)"
          >
            {{ section }}
          </v-tab>
        </v-tabs>
        <keep-alive>
          <!-- dont't refresh each component on tab changes -->
          <game-instance-table v-if="tab === 0" />
          <game-install-table v-else-if="tab === 1" />
          <core-component-table v-else />
        </keep-alive>
      </v-row>
    </div>
  </v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import { mapActions, mapState } from 'vuex';
import { get, isEmpty } from 'lodash';
import { fetchData } from '@/helpers';
import { labels } from '@/assets/texts.json';

export default {
  name: 'environment',
  props: {
    envName: {
      type: String,
      default: '',
      required: false,
    },
    id: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  components: {
    coreComponentTable: () => import('@/components/coreComponentTable.vue'),
    environmentInfo: () => import('@/components/environmentInfo.vue'),
    environmentWarning: () => import('@/components/environmentWarning.vue'),
    gameInstallTable: () => import('@/components/gameInstallTable.vue'),
    gameInstanceTable: () => import('@/components/gameInstanceTable.vue'),
  },
  mounted() {
    if (this.environmentID) { this.fetchInitialData(); }
  },
  beforeDestroy() {
    this.clearInterval();
  },
  computed: {
    ...mapState('fetch', [
      'environment',
      'environmentFetchError',
      'environments',
      'permissions',
    ]),
    ...mapState('shared', [
      'loading',
    ]),
    canViewCore() {
      return this.permissions.k8s_view_pods;
    },
    canViewPromotions() {
      return this.permissions.game_promotion_view;
    },
    environmentID() {
      // Check if we got the environment ID from the route params,
      // otherwise get it by name from `environments`
      if (!isEmpty(this.id)) return this.id;
      const id = get(
        this.environments.filter((e) => e.name === this.envName)[0],
        'id',
      );
      return id;
    },
    sections() {
      return [
        this.labels.gameInstances,
        this.labels.newGame,
        this.labels.coreComponents,
      ];
    },
  },
  data: () => ({
    headers: {
      coreComponents: [
        {
          text: 'Service',
          value: 'metadata.name',
        },
        {
          text: 'Version',
          value: 'version',
        },
        {
          text: 'Repository',
          value: 'repo',
        },
        {
          text: 'Started',
          value: 'started',
        },
        {
          text: 'Status',
          value: 'last_condition',
        },
        {
          text: 'Replicas',
          value: 'replicas', // slot (status.ready_replicas / status.replicas)
        },
        {
          text: 'Actions',
          value: 'actions',
        },

      ],
      gameInstall: [
        {
          text: 'Provider',
          value: 'provider',
        },
        {
          text: 'Game',
          value: 'image',
        },
        {
          text: 'Installed Versions',
          value: 'installed_backends',
          sortable: false,
        },
      ],
    },
    labels,
    tab: 0,
  }),
  methods: {
    ...mapActions({
      clearInterval: 'fetch/clearInterval',
      fetchEnvironment: 'fetch/fetchEnvironment',
      fetchEnvironmentPermissions: 'fetch/fetchEnvironmentPermissions',
      toggleLobby: 'shared/toggleLobby',
    }),
    fetchInitialData() {
      this.fetchEnvironment(this.environmentID);
      this.fetchEnvironmentPermissions(this.environmentID);
    },
    isTabDisabled(tab) {
      return this.loading ? true : (tab === this.sections[2] && !this.canViewCore);
    },
    launchLobby() {
      this.toggleLobby(true);
      fetchData({
        url: `/agent/lobby/${this.environmentID}`,
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.toggleLobby(false);
        window.open(this.environment.lobby_url, '_blank').focus();
      });
    },
  },
  watch: {
    environmentID() {
      this.fetchInitialData();
    },
  },
};
</script>

<style scoped>
</style>
